(function () {
"use strict";
window.addEventListener("DOMContentLoaded", (event) => {
    console.log("DOM fully loaded and parsed");
    const videoObject = document.querySelector('#intro-video video');
    const startTime = new Date().getTime();

    if(videoObject) {
        videoObject.addEventListener('canplaythrough', () => {
            let timeout = (new Date().getTime() - startTime) < 2000 ? 1000 : 0;
            setTimeout(() => {
                document.querySelector('body').classList.remove('is-loading');
                videoObject.play();
            }, timeout)
        });

        videoObject.addEventListener('ended', () => {
            let layer = document.querySelector('#intro-video');
            layer.classList.add('finished');
            layer.addEventListener("transitionend", event => {
                console.log("Transition ended", event.target);
                document.querySelector('body').classList.remove('is-playing');
                event.target.remove();
            });
        });

        let source = document.createElement('source');
        source.setAttribute('src', window.matchMedia("(orientation: portrait)").matches ? './assets/video/intro-portrait.mp4' : './assets/video/intro.mp4');
        source.setAttribute('type', 'video/mp4')
        videoObject.appendChild(source);

        videoObject.addEventListener('touchstart', e => {
            console.log('Touchstart')
            e.preventDefault();
            videoObject.pause();
        })

        videoObject.addEventListener('touchend', e => {
            console.log('Touchend')
            videoObject.play();
        }, {passive: true})

        videoObject.addEventListener('touchcancel', e => {
            console.log('Touch cancel')
            videoObject.play();
        }, {passive: true})

        document.querySelector('.toggle-audio-btn').addEventListener('click', e => {
            videoObject.muted = !videoObject.muted;
            e.target.classList.toggle('active')
        });
    }else{
        let timeout = (new Date().getTime() - startTime) < 2000 ? 1000 : 0;
        setTimeout(() => {
            document.querySelector('body').classList.remove('is-loading');
        }, timeout)
    }

    document.addEventListener('mousemove', function(e) {
        let cursor = document.querySelector('.cursor');
        let left = e.pageX;
        let top = e.pageY;
        if(cursor){
            cursor.style.left = left + 'px';
            cursor.style.top = top + 'px';
        }
    });

    document.querySelector('.contact-box').addEventListener("mouseleave", function (event) {
        let cursor = document.querySelector('.cursor');
        if(cursor){
            cursor.classList.add('zoom');
        }
    });

    document.querySelector('.contact-box').addEventListener("mouseover", function (event) {
        let cursor = document.querySelector('.cursor');
        if(cursor){
            cursor.classList.remove('zoom');
        }
    });


    document.querySelector('aside .close-btn').addEventListener('click',e=>{
        e.preventDefault();
        document.querySelector('aside').classList.remove('open');
    });

    document.querySelectorAll("a[data-target='aside']").forEach(e=>{
        e.addEventListener('click', event =>{
           event.preventDefault();
           let contentURL = event.target.href;
           let contentContainer = document.querySelector('aside');

            fetch(contentURL)  // replace with your URL
                .then(response => response.text())  // convert the response to text
                .then(html => {
                    let parser = new DOMParser();
                    let doc = parser.parseFromString(html, 'text/html');
                    contentContainer.querySelector('.body').innerHTML = doc.querySelector('section').innerHTML;

                    if(!contentContainer.classList.contains('open')){
                        contentContainer.classList.add('open');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        });
    })

    document.getElementById('contact-form').addEventListener('submit', event =>  {
        // Prevent the form from submitting the traditional way
        console.log("Submit form",  event.target);
        event.preventDefault();
        let form = event.target;
        let url = form.action;
        let method = form.method;
        let formData = new FormData(form);
        if(form.querySelector('.msg')) {
            form.querySelector('.msg').remove();
        }
        fetch(url, {
            method: method,
            body: formData
        })
        .then(response => {
            console.log(response)
            return response.json()
        }) // Assuming server responds with JSON
        .then(data => {
            if(data.status !== 200){
                throw new Error(data.message)
            }
            let successMessage = document.createElement('p');
            successMessage.classList.add('msg','successMsg');
            successMessage.innerHTML = "👍 Vielen Dank für Ihre Anfrage.<br>Die IT-Jungs 🧑‍💻 melden sich umgehend bei Ihnen.";
            document.getElementById('contact-form').parentElement.append(successMessage);
            document.getElementById('contact-form').remove();
        })
        .catch(error => {
            let errorMessage = document.createElement('p');
            errorMessage.classList.add('msg','errorMsg');
            errorMessage.innerHTML = error.message;
            document.getElementById('contact-form').prepend(errorMessage);
        });
    });

})}());